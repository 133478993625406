<template>
  <b-container fluid class="ranking-content p-4">
    <h3 class="text-red" style="font-size: 1.3rem; font-weight: 300">
      {{ $t("page.results.emotionalRanking") }}
    </h3>
    <p style="font-size: 0.8rem; font-weight: 300">
      {{ $t("page.results.rankingPageDescription") }}
    </p>

    <b-container v-if="display === 'grid'" fluid class="px-0">
      <b-row class="mt-5">
        <b-col
          cols="12"
          md="6"
          lg="4"
          :key="index"
          v-for="(video, index) of videoData"
        >
          <video-card
            
            :index="index"
            :video="videoData[index]"
            :active="video.rank === 1"
          ></video-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="display === 'list'" fluid class="px-0">
      <old-video-table
        v-if="jsonVersion < 6"
        @clickedDetails="viewVideoAnalysis($event)"
        :data="videoData"
      >
      </old-video-table>
      <video-table
        v-else
        @clickedDetails="viewVideoAnalysis($event)"
        :data="videoData"
      ></video-table>
    </b-container>
  </b-container>
</template>

<script>
import VideoCard from "../components/Results/VideoCard";
import VideoTable from "../components/Results/VideoTable";
import { mapState } from "vuex";
import OldVideoTable from "../components/Results/OldVideoTable.vue";

export default {
  components: {
    VideoCard,
    VideoTable,
    OldVideoTable
  },
  computed: {
    ...mapState("analysis", ["display", "videoData", "jsonVersion"]),

    isGuestMode() {
      return this.$store.getters["analysis/isGuestMode"];
    }
  },

  methods: {
    getColor(value) {
      switch (value) {
        case "high":
          return "green";
        case "average":
        case "middle":
          return "orange";
        case "low":
          return "red";
        default:
          return "gray";
      }
    },

    getDisplayedTitle(title) {
      if (title === "Media Optimization" || title === "Digital Performance") {
        return title;
      }
      const strings = title.split(" ");
      if (strings.length > 1) {
        return strings[1];
      } else {
        return strings[0];
      }
    },

    getColumns(title) {
      switch (title) {
        case "Digital Performance":
          return 2;
        case "Media Optimization":
          return 2;
        case "Media Effectiveness":
          return 1;
        default:
          return 1;
      }
    },

    viewVideoAnalysis(videoId) {
      console.log(videoId);
      this.$store.commit("analysis/SET_COMPARISON", false);
      this.$store.commit("analysis/SET_CURRENT_VIDEO_INDEX", videoId);
      if (this.isGuestMode) {
        this.$router.push({
          name: "shareScore"
        });
      } else {
        this.$router.push({
          name: "score"
        });
      }
    }
  }
};
</script>

<style scoped>
.col-12 {
  margin-top: 2rem;
}

.col-12:nth-child(n + 4) {
  margin-top: 2rem;
}

@media screen and (min-width: 992px) {
  .col-12 {
    margin-top: 0;
  }
}

.ranking-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ranking-content p {
  width: 68%;
}

.text-large {
  font-weight: bold;
  font-size: 1.4em;
}

.text-small {
  font-weight: bold;
  font-size: 0.65em;
}

.arrow-icon {
  padding: 4px;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
}

.arrow-icon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
}

.borders {
  border-top: 1px solid var(--gray-light);
}

.green {
  color: green;
}

.red {
  color: red;
}

.clickable:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
